<template>
  <div key="distributor_set">
    <template v-if="sku">
      <div class="head-container">
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-edit" :disabled="!selection || !selection.length" @click="batchEdit">批量修改</el-button>
        <el-button class="filter-item" type="danger" icon="el-icon-delete" :disabled="!selection || !selection.length" :loading="delLoading" @click="batchRemove">批量删除</el-button>
      </div>

      <el-table ref="list" v-loading="loading" :data="data" row-key="id" @selection-change="handleSelectionChange" height="200">
        <el-table-column type="selection" width="35" reserve-selection />
        <el-table-column prop="distributorName" label="经销商名称" min-width="100" />
        <el-table-column prop="price" label="统一零售价" min-width="80" :formatter="$price" />
        <el-table-column width="100" fixed="right">
          <div slot-scope="scope" class="row-commands">
            <el-button size="mini" type="text" @click="edit([scope.row])">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>

      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </template>
    <span class="fc-g" v-else>请先在左侧选择要进行零售价管理的商品。</span>

    <eForm ref="form" @complete="handleSkuChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { delByDistributor } from "@/api/retailPrice";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  props: {
    sku: Object
  },
  data() {
    return {
      selection: null
    };
  },
  watch: {
    sku: "handleSkuChange"
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (!this.sku) return false;
      this.url = "api/shop/distributorPrice";
      this.params = {
        goodsSkuId: this.sku.id
      };
      return true;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    handleSkuChange() {
      this.$refs.list && this.$refs.list.clearSelection();
      this.selection = null;
      this.toQuery();
    },
    subDelete(id) {
      this.delLoading = true;
      delByDistributor([id])
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    batchRemove() {
      if (this.selection && this.selection.length) {
        this.$confirm(
          `确定要删除当前商品勾选的${this.selection.length}条指定经销商的零售价吗？`,
          "操作确认",
          {
            type: "warning"
          }
        ).then(res => {
          this.delLoading = true;
          let ids = this.selection.map(o => {
            return o.id;
          });
          delByDistributor(ids)
            .then(res => {
              this.$notify({
                title: "批量删除成功",
                type: "success",
                duration: 2500
              });
              this.handleSkuChange();
            })
            .finally(err => {
              this.delLoading = false;
            });
        });
      }
    },
    add() {
      this.$refs.form &&
        this.$refs.form.resetForm(null, {
          _isAdd: true,
          goodsSkuId: this.sku.id
        });
    },
    batchEdit() {
      if (this.selection && this.selection.length) {
        let same = true,
          price = this.selection[0].price;
        for (let i = 1, l = this.selection.length; i < l; i++) {
          if (this.selection[i].price !== price) {
            same = false;
            break;
          }
        }
        if (same) {
          this.edit(this.selection);
        } else {
          this.$confirm(
            `勾选的经销商当前的零售价不同，编辑时将暂时统一价格为：${this.$price(
              price
            )}。<br />确定要同时对勾选的经销商重新编辑零售价吗？`,
            "系统提示",
            {
              type: "warning",
              dangerouslyUseHTMLString: true
            }
          ).then(_ => {
            this.edit(this.selection);
          });
        }
      }
    },
    edit(rows) {
      if (rows && rows.length) {
        this.$refs.form &&
          this.$refs.form.resetForm({
            _isAdd: false,
            goodsSkuId: this.sku.id,
            ids: rows.map(o => {
              return o.distributorEntId;
            }),
            names: null,
            price: rows[0].price
          });
      }
    }
  },
  mounted() {
    this.handleSkuChange();
  }
};
</script>