<template>
  <el-dialog :visible.sync="visible" :title="form && form._isAdd ? '新增零售价格' : '编辑零售价格'" append-to-body width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
      <el-form-item prop="ids" label="经销商">
        <quick-select v-model="form.ids" :label.sync="form.names" multiple url="api/distributor" value-field="enterpriseId" placeholder="选择经销商" filterable style="width: 100%;" />
      </el-form-item>
      <el-form-item prop="price" label="零售价格">
        <price-input v-model="form.price" :min="0" style="width: 180px;" />
      </el-form-item>
      <!-- <el-form-item prop="enable" label="是否启用">
        <el-switch v-model="form.enable" />
      </el-form-item>-->
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editByDistributor } from "@/api/retailPrice";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        ids: [
          { required: true, message: "请选择经销商" },
          {
            type: "array",
            min: 1,
            message: "请选择经销商",
            trigger: "change"
          }
        ],
        price: [{ required: true, message: "请填写零售价" }]
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          let ds = this.form.ids.map((id, inx) => {
            return {
              distributorEntId: id,
              distributorName: this.form.names[inx],
              price: this.form.price,
              goodsSkuId: this.form.goodsSkuId
            };
          });
          editByDistributor(ds)
            .then(res => {
              this.$notify({
                title: "设置零售价成功",
                type: "success",
                duration: 2500
              });
              this.$emit("complete");
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form, extend) {
      this.form =
        form ||
        Object.assign(
          {
            ids: null,
            names: null,
            price: 0
          },
          extend || {}
        );
      this.visible = true;
    }
  }
};
</script>